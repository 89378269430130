import UpgradeModalGeneric from "./upgrade-modal";
import { markTipSeen } from "frontend/api";
import { TipName } from "shared/datamodel/schemas";
import consts from "shared/consts";

export default function UpgradeModalAfterDowngrade({
  showUpgradeModalAfterDowngrade,
  setShowUpgradeModal,
  type,
}: {
  showUpgradeModalAfterDowngrade: (show: boolean) => void;
  setShowUpgradeModal: (show: boolean) => void;
  type: "downgrade" | "subscriptionCanceled";
}) {
  const mainTitle = type === "downgrade" ? "You are on the free plan" : "Something went wrong with your payment";
  const upgradeButtonText = type === "downgrade" ? undefined : "To upgrade, update payment method";
  const hideUpgradeModal = () => {
    showUpgradeModalAfterDowngrade(false);
    type === "downgrade" ? markTipSeen(TipName.downgradeNotice) : markTipSeen(TipName.subscriptionCanceledNotice);
  };
  return (
    <UpgradeModalGeneric
      title={"Upgrade to edit more canvases"}
      mainTitle={mainTitle}
      content={
        "You now have only <b> 3 editable canvases</b>. But don’t worry! We’ve got your back. To unlock them you can upgrade to pro at any time."
      }
      bulletpoints={[
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      trialDays={consts.TRIAL_DAYS_DEFAULT_PERIOD}
      image={"/images/upgrade-modal-images/upgrade-for-unlimited-canvases.png"}
      onDismiss={hideUpgradeModal}
      onUpgrade={() => {
        setShowUpgradeModal(true);
        hideUpgradeModal();
      }}
      showCancelLine={false}
      showDismissButton={true}
      upgradeButtonText={upgradeButtonText}
      trackingEvent={"UpgradeModalAfterDowngrade " + type}
      insideModal={false}
    />
  );
}
